import React, { useState, useEffect } from "react"; import {
    ListGroup,
    ListGroupItem,
    Row,
    Col,
    Form,
    FormInput,
    FormGroup,
    FormCheckbox,
    FormFeedback,
    FormSelect,
    Button
} from "shards-react";
import Athlete from "../utils/athlete";
import GenderButtonGroups from "./TeamDetailComponents/GenderButtonGroups";

const AthleteDetails = (props) => {
    let [localAthlete, setLocalAthlete] = useState();
    let [isDirty, setIsDirty] = useState(false);

    useEffect(() => {
        setLocalAthlete(props.athlete);
    }, [props.athlete]);

    let setAgeGroup = (choice) => {
        const newAthlete = new Athlete();
        newAthlete.CopyFromObject(localAthlete);

        newAthlete.AgeGroupId = '' + choice;
        setIsDirty(true);

        setLocalAthlete(newAthlete);

    }

    let onSetGender = (data) => {
        console.log({ setGender: data });
        const newAthlete = new Athlete();
        newAthlete.CopyFromObject(localAthlete);

        newAthlete.Gender = data;
        setIsDirty(true);

        setLocalAthlete(newAthlete);

    }

    let deleteAthlete = () => {
        if (window.confirm("Are you sure you wish to delete this Athlere.  Click Ok to confirm")) {
            props.onDeleteAthelete(localAthlete);
        }

    }

    let handleTeamNameChange = (event) => {

        //let newAthlete = { ...localAthlete };

        console.log({ preupdated: localAthlete })
        const newAthlete = new Athlete();
        newAthlete.CopyFromObject(localAthlete);
        console.log({ minupdated: newAthlete })

        switch (event.target.id) {
            case "feName":
                newAthlete.Name = event.target.value;
                break;
            case "feDOB":
                newAthlete.Dob = event.target.value;
                break;
            case "feAlsoKnownAs":
                newAthlete.AlsoKnownAs = event.target.value;
                break;
            case "feAllergies":
                newAthlete.Allergies = event.target.value;
                break;
            case "feConferenceId":
                newAthlete.ConferenceId = event.target.value;
                break;
            case "feParentName":
                newAthlete.ParentName = event.target.value;
                break;
            case "feParentCell":
                newAthlete.ParentCell = event.target.value;
                break;
            default:
        }

        console.log({ afterpdated: newAthlete })

        setLocalAthlete(newAthlete);

        setIsDirty(true);
    }

    let onSubmit = (formData) => {
        formData.preventDefault()

        if (localAthlete.AgeGroupId === '') {
            window.alert("Please select an age group. If you do not see an age group add one to the team.")
            return;

        }
        if (isDirty)
            props.onSaveAthlete(localAthlete);
        setIsDirty(false);

    }

    let checkFormValidity = (event) => {


        return !isDirty;
    }

    console.log({ localAthlete })

    if (localAthlete === undefined) {
        return "Loading";
    }

    return (
        <ListGroup flush>
            <ListGroupItem className="p-3">
                <Row>
                    <Col>
                        <Form onSubmit={onSubmit}>
                            <Row form>
                                <Col md="6" className="form-group">
                                    <label htmlFor="feName">Name</label>
                                    <FormInput
                                        id="feName"
                                        type="text"
                                        placeholder="Name"
                                        onChange={handleTeamNameChange}
                                        value={localAthlete.Name}
                                        valid={localAthlete.Name.trim().length > 0}
                                        invalid={localAthlete.Name.trim().length === 0}
                                        required={true}
                                    />
                                    <FormFeedback valid>Name looks good!</FormFeedback>
                                    <FormFeedback invalid>Name should not be empty!</FormFeedback>
                                </Col>
                                <Col md="6">
                                    <label htmlFor="feAlsoKnownAs">Nickname</label>
                                    <FormInput
                                        id="feAlsoKnownAs"
                                        type="text"
                                        placeholder="Also known as"
                                        value={localAthlete.AlsoKnownAs}
                                        onChange={handleTeamNameChange}
                                    />
                                </Col>
                            </Row>
                            <Row form>
                                <Col md="6" className="form-group">
                                    <label htmlFor="feDOB">Date of birth</label>
                                    <FormInput
                                        id="feDOB"
                                        type="text"
                                        placeholder="Date of birth"
                                        value={localAthlete.Dob}
                                        onChange={handleTeamNameChange}
                                    />
                                </Col>
                                <Col md="6">
                                    <label htmlFor="feAllergies">Allergies</label>
                                    <FormInput
                                        id="feAllergies"
                                        type="text"
                                        placeholder="Allergies"
                                        value={localAthlete.Allergies}
                                        onChange={handleTeamNameChange}
                                    />
                                </Col>
                            </Row>
                            <Row form>
                                <Col md="6" className="form-group">
                                    <label htmlFor="feConferenceId">Conference Id</label>
                                    <FormInput
                                        id="feConferenceId"
                                        type="text"
                                        placeholder="Conference Id"
                                        value={localAthlete.ConferenceId}
                                        onChange={handleTeamNameChange}
                                    />
                                </Col>
                                <Col md="6">
                                    <label htmlFor="feAllergies">Gender</label>
                                    <GenderButtonGroups initialGender={localAthlete.Gender} setGender={onSetGender} groupVerbage={false} />
                                </Col>
                            </Row>
                            <Row form>
                                <Col md="6" className="form-group">
                                    <label htmlFor="feParentName">Parent Name</label>
                                    <FormInput
                                        id="feParentName"
                                        type="text"
                                        placeholder="Parent Name"
                                        value={localAthlete.ParentName}
                                        onChange={handleTeamNameChange}
                                    />
                                </Col>
                                <Col md="6">
                                    <label htmlFor="feParentCell">Parent cell</label>
                                    <FormInput
                                        id="feParentCell"
                                        type="phone"
                                        placeholder="Parent cell"
                                        value={localAthlete.ParentCell}
                                        onChange={handleTeamNameChange}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6">
                                    <label htmlFor="feAgeGroup">Age Group</label>
                                    <FormSelect id="feAgeGroup"
                                        defaultValue={props.ageGroups === undefined ? '' : props.ageGroups[0].Name}
                                        onChange={(choice) => {
                                            setAgeGroup(choice.target.value);
                                        }}
                                        valid={localAthlete.AgeGroupId !== ''}
                                        invalid={localAthlete.AgeGroupId === ''}
                                    >
                                        <option value='' key={'blank'}>Choose...</option>
                                        {
                                            props.ageGroups.map((e) => {
                                                return <option key={e.AgeGroupId} value={e.AgeGroupId} selected={e.AgeGroupId === props.athlete.AgeGroupId}>{e.GenderDescription}</option>;
                                            })
                                        }

                                    </FormSelect>
                                </Col>
                                <Col md="6">
                                    <Row>
                                        <label htmlFor="actionBut">Actions</label>
                                    </Row>
                                    <Row md="6">
                                        <Col md="6">
                                            <Button id="actionBut" type="submit" disabled={checkFormValidity}>Save the data</Button>
                                        </Col>
                                        <Col md="6">
                                            <Button id="actionButon" onClick={deleteAthlete}>Delete this Athlete</Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </ListGroupItem >
        </ListGroup >
    );
}
export default AthleteDetails;
