const boys = 0
const girls = 1
const mixed = 2

class Gender {
    constructor(id) {
        this.id = parseInt("" + id);
    }

    static get boys() {
        return boys;
    }

    static get girls() {
        return girls;
    }

    static get mixed() {
        return mixed;
    }

    CopyFromObject(sourceGender) {
        this.id = sourceGender.id;
    }

    SetFromCode(genderCode) {
        if (typeof genderCode === "object")
            this.id = genderCode.id
        else {
            genderCode = '' + genderCode

            if (genderCode === "M" || genderCode === "m" || genderCode === "0")
                this.id = 0;
            else if (genderCode === "F" || genderCode === "f" || genderCode === "1")
                this.id = 1;
            else if (genderCode === "b" || genderCode === "B" || genderCode === "2")
                this.id = 2;

        }

    }

    genderCode() {
        if (this.id === 0)
            return "M";
        else if (this.id === 1)
            return "F";
        return "B";
    }

    GenderFormattted(id) {
        if (id === undefined)
            id = this.id;

        id = '' + id;

        let title = "";

        switch (id) {
            case "M":
            case "0":
                title = "Male";
                break;
            case "F":
            case "1":
                title = "Female";
                break;
            case "2":
            case "B":
                title = "Combined";
                break;
            default:
                title = "";
                break;
        };

        return title;
    }
};


export default Gender;
