import React from "react";
import { Container, Row, Col } from "shards-react";
import {
    Card,
    CardHeader,
    ListGroup,
    ListGroupItem
} from "shards-react";

import PageTitle from "../components/common/PageTitle";

const Tutorial = () => (
    <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
            <PageTitle title="Tutorial" subtitle="Learn how to install and use the app" md="12" className="ml-sm-auto mr-sm-auto" />
        </Row>
        <Row>
            <Col lg="4">
                <Card small className="mb-4 pt-3">
                    <CardHeader className="border-bottom text-left">
                        <div className="mb-3 mx-auto">
                            <h4 className="mb-0">Start Here</h4>
                        </div>
                    </CardHeader>
                    <ListGroup flush>
                        <ListGroupItem className="px-4">
                            <p>New for 2025.  Account registration, first Sign-in and team creation</p>
                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/PHdQbPu3MUM?si=lRb5-ALnmpr2wPzJ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </ListGroupItem>
                    </ListGroup>
                </Card>
            </Col>
            <Col lg="4">
                <Card small className="mb-4 pt-3">
                    <CardHeader className="border-bottom text-left">
                        <div className="mb-3 mx-auto">
                            <h4 className="mb-0">Roster</h4>

                        </div>
                    </CardHeader>
                    <ListGroup flush>
                        <ListGroupItem className="px-4">
                            <p>Creating and updating your team roster</p>
                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/D-PRvEiu_NU?si=iDo9pYQpSvjLADyj" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </ListGroupItem>
                    </ListGroup>
                </Card>
            </Col>
            <Col lg="4">
                <Card small className="mb-4 pt-3">
                    <CardHeader className="border-bottom text-left">
                        <div className="mb-3 mx-auto">
                            <h4 className="mb-0">Sign-Ups</h4>

                        </div>
                    </CardHeader>
                    <ListGroup flush>
                        <ListGroupItem className="px-4">
                            <p>Sign your athletes up for races and field events</p>
                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/4mfsdpPPRRs?si=rBAl0Qh4ujvQGYdF" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </ListGroupItem>
                    </ListGroup>
                </Card>
            </Col>
        </Row>
        <Row>
            <Col lg="6">
                <Card small className="mb-4 pt-3">
                    <CardHeader className="border-bottom text-left">
                        <div className="mb-3 mx-auto">
                            <h4 className="mb-0">Get Your Reports</h4>
                        </div>
                    </CardHeader>
                    <ListGroup flush>
                        <ListGroupItem className="px-4">
                            <p>Grid and Table reports for Race Day.</p>
                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/ZtfZKHpv2b4?si=DO4OOIENqW7W6M5A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </ListGroupItem>
                    </ListGroup>
                </Card>
            </Col>
            <Col lg="6">
                <Card small className="mb-4 pt-3">
                    <CardHeader className="border-bottom text-left">
                        <div className="mb-3 mx-auto">
                            <h4 className="mb-0">Advanced</h4>
                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/NjNJx7LyoNk?si=Zj0_ZJ8Ki-ReTIJo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                    </CardHeader>
                    <ListGroup flush>
                        <ListGroupItem className="px-4">
                            <p>Miscellaneous and advanced features</p>

                        </ListGroupItem>
                    </ListGroup>
                </Card>
            </Col>

        </Row>

    </Container >
);

export default Tutorial;

